import * as types from "../../shared/constants/ActionTypes";
import request from "../../shared/api/axios";

// external action Creator

// ***** Actions

// api fetch status
export const isFetchingCategories = () => ({
  type: types.IS_FETCHING_CATEGORIES
});

// sending all breeds data to store
export const categoriesData = categories => ({
  type: types.CATEGORIES_DATA,
  categories: categories
});

//sending one selected breed
// export const selectedBreed = breedImg => ({
//     type: types.SELECTED_BREED,
//     // selectedBreed: breed,
//     selectedBreedImg: breedImg
// });

// making the scroll menu
// export const scrollBreeds = breed => ({
//     type: types.SCROLL_BREEDS,
//     scrollBreeds: breed
// });

// reset selected breed
// export const resetSelectedBreed = () => ({
//     type: types.RESET_SELECTED_BREED
// });

// ******* Action Creators

//fetch the categories
export const getAllCategories = () => {
  return (dispatch, getState) => {
    // declare a api fetch in progress
    dispatch(isFetchingCategories());
    return request({
      method: "get",
      url: "/categories"
    }).then(response => {
      dispatch(categoriesData(response));

      // making the scroll breeds menu /!\ if not using selectors
      /*
            let scrollData = [];
            response.forEach(element => {
              scrollData.push({
                breedId: element.id,
                breedName: element.name
              });
            });
            dispatch(scrollBreeds(scrollData));
            */
    });
  };
};

// Selected one breed
// export const getOneBreeds = id => {
//   return (dispatch, getState) => {
//     // declare a api fetch in progress
//     dispatch(isFetchingBreeds());
//     dispatch(resetSelectedBreed());

//     // filter the selected breed in store (see the request under)
//     // const breedFilterd = getState().breed.breeds.filter(
//     //   breed => breed.id === id
//     // );
//     // console.log(breedFilterd);

//     // get the selected breed img (seems that it return the breed too)
//     return request({
//       method: "get",
//       url: `/images/search?breed_id=${id}`
//     }).then(breed => {
//       // return the selected breed data & image
//       dispatch(selectedBreed(breed));
//     });
//   };
// };
