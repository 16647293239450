import React, { Component } from "react";
import Axios from "axios";

const initialState = {
  email: "",
  name: "",
  message: "",
  sendedMessage: ""
};
export default class ContactContainer extends Component {
  state = { ...initialState };

  handleChange = event => {
    // console.log(event);
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { name, email, message } = this.state;
    let time = Date();
    const data = { name, email, message, time };

    // send email via a firebase function
    Axios.post(
      "https://us-central1-felikitty-production.cloudfunctions.net/submit",
      data
    )
      // .then(res => {
      //   return Firebase.database()
      //     .ref("contacts")
      //     .push(data);
      // })
      .catch(error => {
        console.log(error);
      });
    this.setState({ ...initialState, sendedMessage: true });
  };

  closeWindow = () => this.setState({ sendedMessage: false });

  render() {
    const { name, email, message, sendedMessage } = this.state;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <div
          style={{
            margin: "auto 1em auto 1am",
            color: "white"
          }}
        >
          <form onSubmit={this.handleSubmit}>
            <label>
              * Your name:
              <br />
              <input
                type="text"
                name="name"
                value={name}
                onChange={this.handleChange}
              />
            </label>
            <br />
            <label>
              * e-mail:
              <br />
              <input
                type="email"
                name="email"
                value={email}
                onChange={this.handleChange}
              />
            </label>
            <br />
            <label>
              * Message
              <br />
              <textarea
                type="text"
                name="message"
                value={message}
                onChange={this.handleChange}
              />
            </label>
            <br />
            <input
              type="submit"
              value="submit"
              disabled={
                !this.state.email || !this.state.name || !this.state.message
              }
            />
          </form>
        </div>
        {sendedMessage && (
          <article className="message" style={{ maxWidth: "250px" }}>
            <div className="message-header">
              <p>Message sended</p>
              <button
                className="delete"
                aria-label="delete"
                onClick={this.closeWindow}
              />
            </div>
            <div className="message-body">
              Thank you for contacting us. We will answer shortly.
            </div>
          </article>
        )}
      </div>
    );
  }
}
