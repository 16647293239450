import { IS_FETCHING } from "../../shared/constants/ActionTypes";

const initialState = {
  isFetching: false
};

const home = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING:
      return { ...state, isFetching: !state.isFetching };
    // case RECEIVED_BRIEFS:
    //     return {
    //         ...state,
    //         isFetchingBriefs: !state.isFetchingBriefs,
    //         briefs: action.briefs
    //     };
    // case ADD_BRIEF:
    //     return {
    //         ...state,
    //         briefs: [...state.briefs, action.briefValues]
    //     };
    // case BRIEFS_FILTER:
    //     return {
    //         ...state,
    //         briefFilter: action.briefFilter
    //     };
    default:
      return state;
  }
};

export default home;
