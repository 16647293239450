import {
  IS_FETCHING_CATEGORIES,
  CATEGORIES_DATA,
  //   DROPDOWN_CATEGORIES,
  SELECTED_CATEGORIES,
  RESET_SELECTED_CATEGORIES
} from "../../shared/constants/ActionTypes";

const initialState = {
  isFetchingCategories: false,
  categories: [],
  selectedCategories: {},
  dropdownCategories: []
};

const categories = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING_CATEGORIES:
      return { ...state, isFetchingCategories: !state.isFetchingCategories };
    case CATEGORIES_DATA: //all Categories
      return {
        ...state,
        isFetchingCategories: !state.isFetchingCategories,
        categories: action.categories
      };
    // case DROPDOWN_CATEGORIES: // Categories menu
    //   return {
    //     ...state,
    //     scrollCategories: action.scrollCategories
    //   };
    case RESET_SELECTED_CATEGORIES: // Categories menu
      return {
        ...state,
        selectedCategories: {}
      };
    case SELECTED_CATEGORIES: // one breed selected
      // console.log(action.selectedBreedImg);
      return {
        ...state,
        isFetchingCategories: !state.isFetchingCategories,
        selectedCategories: action.selectedCategories
      };

    default:
      return state;
  }
};

export default categories;
