import React, { Suspense, Component } from "react";
import request from "../../../shared/api/axios";
import { compose } from "recompose";
import PropTypes from "prop-types";
//Redux
import { connect } from "react-redux";
import { getAllCategories } from "../actionCreators";
import { getAllBreeds } from "../../Breed/actionCreators";
import { setPageTitle } from "../../../shared/actionCreators";
// styled Component
import Select from "./../../../layout/styledComponents/select";
import MainBorder from "../../../layout/styledComponents/mainBorder";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bulma-helpers/css/bulma-helpers.min.css";

// lazy loading for ui render content faster befor image
// const Search = React.lazy(() => import("./Search"));
const Gallery = React.lazy(() => import("./../../shared/Gallery"));

class SearchContainer extends Component {
  state = {
    cats: undefined,
    categorieSelected: "",
    breedSelected: "",
    refresh: false
  };
  /*
&limit=3
&order=Rand Desc Asc
&size=thumb
https://api.thecatapi.com/v1/breeds breed_ids=beng or ?breed_id={{selected_breed.id}} ???
https://api.thecatapi.com/v1/categories dropdown category_ids=1 or category_ids=1,4,9 ...
?mime_types=gif
?mime_types=jpg,png
*/
  componentDidMount = () => {
    //set the title page
    this.props.setPageTitle("Search");
    // fetch breed & categories for making dropdown
    this.props.getAllCategories();
    this.props.getAllBreeds();
  };

  componentDidUpdate(prevProps, prevState) {
    // check if user click reload icon
    if (prevState.refresh !== this.state.refresh) {
      this.getData();
    }
  }

  getData = () => {
    this.setState({ isFetching: true });
    const { categorieSelected, breedSelected } = this.state;
    const { network } = this.props;
    let size = "";
    network === "4g" ? (size = "full") : (size = "small");
    let url =
      "/images/search?breed_ids=" +
      breedSelected +
      "&category_ids=" +
      categorieSelected +
      "&mime_types=gif,jpg,png&limit=3&size=" +
      size +
      "&order=RAND";
    request({
      method: "get",
      url: url
    }).then(resp => {
      // console.log(resp);
      this.setState({
        cats: resp,
        isFetching: "done"
        // catImg: fetchImg
      });
    });
  };

  categorieSelected = event => {
    // console.log(event.target.value);
    this.setState(
      {
        categorieSelected: event.target.value
      },
      () => {
        this.getData();
      }
    );
  };

  breedSelected = event => {
    this.setState(
      {
        breedSelected: event.target.value
      },
      () => {
        this.getData();
      }
    );
  };

  onRefresh = () => {
    this.setState({
      refresh: true
    });
  };

  render() {
    const { cats, categorieSelected, breedSelected, isFetching } = this.state;
    const { categories, scrollBreeds } = this.props;
    const results =
      isFetching === "done" &&
      cats.map(cat => <Gallery key={cat.id} id={cat.id} url={cat.url} />);

    const categoriesDropdown =
      categories.length > 0 &&
      categories.map(categorie => (
        <option key={categorie.id} value={categorie.id}>
          {categorie.name}
        </option>
      ));

    const breedsDropdown =
      scrollBreeds.length > 0 &&
      scrollBreeds.map(breed => (
        <option key={breed.breedId} value={breed.breedId}>
          {breed.breedName}
        </option>
      ));

    return (
      <div>
        <div className="is-flex flex-row nowrap justify-center">
          <div>
            <form>
              <div className="field">
                <div>
                  <Select
                    onChange={this.categorieSelected}
                    value={categorieSelected}
                    disabled={breedSelected !== "" ? true : false}
                  >
                    <option value="">None</option>
                    {categoriesDropdown}
                  </Select>
                </div>
              </div>
            </form>
          </div>
          <div style={{ marginRight: "1em" }}>OR</div>
          <div>
            <form>
              <div className="field">
                <div>
                  <Select
                    onChange={this.breedSelected}
                    value={breedSelected}
                    disabled={categorieSelected !== "" ? true : false}
                  >
                    <option value="">None</option>
                    {breedsDropdown}
                  </Select>
                </div>
              </div>
            </form>
          </div>
          <div className="has-margin-left-10 has-text-white">
            <FontAwesomeIcon
              icon={["fas", "sync-alt"]}
              onClick={this.onRefresh}
            />
          </div>
        </div>
        <MainBorder>
          <Suspense
            fallback={
              <progress className="progress is-small is-primary" max="100">
                Loading...
              </progress>
            }
          >
            <div className="is-flex flex-column justify-center">{results}</div>
          </Suspense>
        </MainBorder>
      </div>
    );
  }
}

SearchContainer.propTypes = {
  categories: PropTypes.array,
  getAllBreeds: PropTypes.func,
  getAllCategories: PropTypes.func,
  network: PropTypes.string,
  scrollBreeds: PropTypes.array,
  setPageTitle: PropTypes.func
};

function mapStateToProps(state) {
  const {
    isFetchingCategories,
    categories,
    selectedCategories,
    dropdownCategories
  } = state.search;

  const { scrollBreeds } = state.breed;
  const { network } = state.shared;

  return {
    isFetchingCategories,
    categories,
    selectedCategories,
    dropdownCategories,
    scrollBreeds,
    network
    // scrollBreeds: scrollBreedsMenu(state)
  };
}

export default compose(
  connect(
    mapStateToProps,
    { getAllCategories, setPageTitle, getAllBreeds }
  )
  // withTheme()
)(SearchContainer);
