import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "recompose";
//Redux
import { connect } from "react-redux";
import { setPageTitle } from "../../../shared/actionCreators";
import { getAllStories, getOneStory } from "../actionCreators";

import MainBorder from "../../../layout/styledComponents/mainBorder";
import "bulma-helpers/css/bulma-helpers.min.css";

class storiesContainer extends Component {
  componentDidMount = () => {
    //set the title page
    this.props.setPageTitle("Cats Stories");
    // get the stories
    this.props.getAllStories();

    // https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.lovemeow.com%2Ffeeds%2Ffeed.rss&api_key=1jocx7kvothnw9mvjz7ycn1tu4zi5yyc85bvpotp
  };

  storyDetails = date => {
    this.props.getOneStory(date);
    // this.propos.history.push("/story/:id");
  };

  render() {
    const { stories } = this.props;

    const storiesTitles =
      stories.length > 0 &&
      stories.map(n => (
        <div key={n.pubDate}>
          <time dateTime={`${n.pubDate}`}>{n.pubDate}</time>
          <header>
            <Link to="/story" onClick={() => this.storyDetails(n.pubDate)}>
              <h1 className="has-text-white">{n.title}</h1>
            </Link>
          </header>
          <section>
            <img src={`${n.thumbnail}`} alt={`${n.title}`} />
          </section>
        </div>
      ));

    return (
      <MainBorder style={{ margin: "0", paddingTop: "1em" }}>
        <article className="has-padding-left-10 has-padding-right-10">
          {storiesTitles}
        </article>
      </MainBorder>
    );
  }
}

function mapStateToProps(state) {
  const { isFetchingStories, stories, selectedStory } = state.stories;
  return {
    isFetchingStories,
    stories,
    selectedStory
  };
}

export default compose(
  connect(
    mapStateToProps,
    { getAllStories, getOneStory, setPageTitle }
  )
)(storiesContainer);
