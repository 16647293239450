import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import Policy from "./../privacy_policy.md";
export default class Privacy extends Component {
  state = { terms: null };
  componentDidMount = () => {
    fetch(Policy)
      .then(response => response.text())
      .then(text => {
        this.setState({ terms: text });
      });
  };

  render() {
    return (
      <div style={{ padding: "0 1em" }}>
        <ReactMarkdown source={this.state.terms} />
      </div>
    );
  }
}
