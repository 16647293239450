import styled from "styled-components";

const MainBorder = styled.div`
  /* display: flex;
  flex-direction: flex-row;
  align-content: justify-center; */
  border-top: 1px solid white;
  /* border-bottom: 0px solid white; */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin: 1em 0 0 0;
  padding: 0;
`;

export default MainBorder;
