import { combineReducers } from "redux";
import shared from "./reducer";
import { reducer as home } from "./../features/Home";
import { reducer as breed } from "./../features/Breed";
import { reducer as search } from "./../features/Search";
import { reducer as stories } from "./../features/Stories";

// Combines all reducers to the store
export default combineReducers({
  home,
  breed,
  shared,
  search,
  stories
});
