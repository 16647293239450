import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Helmet } from "react-helmet";
//Redux
import { connect } from "react-redux";

// import "bulma/css/bulma.css";
import "bulma-helpers/css/bulma-helpers.min.css";

/*
Receive page title from store
*/
class Nav extends Component {
  state = {
    isOpen: false
  };

  openBurger = title => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { isOpen } = this.state;
    // burger open/close
    const isActive = isOpen ? "is-active" : "";
    // console.log(this.props.location.pathname);
    return (
      <div>
        <Helmet>
          <title>{this.props.pageTitle} - felikitty.com</title>
          <link
            rel="canonical"
            href={`https://felikitty.com${this.props.location.pathname}`}
          />
          <meta
            name="description"
            content="Information about cat breeds, cute cat pictures, cat stories and more"
          />
        </Helmet>
        <nav
          className="navbar is-marginless"
          role="navigation"
          aria-label="main navigation"
          style={{
            background: "linear-gradient(to bottom, #9360fe 0%,#5598FE 100%)"
            // height: "150px"
          }}
        >
          <div className="navbar-brand">
            <Link
              to="/"
              className="navbar-item has-text-white is-size-4 has-text-weight-bold"
            >
              <img src="./assets/logo.png" alt="logo" />
            </Link>
            <div className="navbar-item has-text-white is-size-4 had-has-margin-top-20">
              {this.props.pageTitle}
            </div>
            {/* eslint-disable-next-line */}
            <a
              role="button"
              className={`navbar-burger burger has-text-white ${isActive}`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasic"
              onClick={this.openBurger}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>
          <div
            id="navbarBasic"
            style={{ backgroundColor: "transparent" }}
            className={`navbar-menu ${isActive}`}
          >
            <div className="navbar-end">
              <Link
                to="/"
                className="navbar-item has-text-white has-text-weight-semibold"
                onClick={this.openBurger}
              >
                Home
              </Link>
              <Link
                to="/breed"
                className="navbar-item has-text-white has-text-weight-semibold"
                onClick={this.openBurger}
              >
                Breeds
              </Link>
              <Link
                to="/random"
                className="navbar-item has-text-white has-text-weight-semibold"
                onClick={this.openBurger}
              >
                Random
              </Link>
              <Link
                to="/search"
                className="navbar-item has-text-white has-text-weight-semibold"
                onClick={this.openBurger}
              >
                Search
              </Link>
              <Link
                to="/stories"
                className="navbar-item has-text-white has-text-weight-semibold"
                onClick={this.openBurger}
              >
                Stories
              </Link>

              <div
                className="navbar-item has-dropdown is-hoverable"
                style={{ backgroundColor: " transparent" }}
              >
                {/* eslint-disable-next-line */}
                <a className="navbar-link has-text-white has-text-weight-semibold">
                  More
                </a>
                <div
                  className="navbar-dropdown is-right"
                  style={{ backgroundColor: " #5598FE" }}
                >
                  <Link
                    to="/privacy"
                    className="navbar-item has-text-white has-text-weight-semibold"
                    onClick={this.openBurger}
                  >
                    Privacy
                  </Link>
                  <Link
                    to="/terms"
                    className="navbar-item has-text-white has-text-weight-semibold"
                    onClick={this.openBurger}
                  >
                    Terms
                  </Link>
                  <Link
                    to="/contact"
                    className="navbar-item has-text-white has-text-weight-semibold"
                    onClick={this.openBurger}
                  >
                    Contact
                  </Link>
                </div>
              </div>

              {/* <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">More</a>
              
              <div className="navbar-dropdown">
              <a className="navbar-item">About</a>
              <a className="navbar-item">Jobs</a>
              <a className="navbar-item">Contact</a>
              <hr className="navbar-divider" />
              <a className="navbar-item">Report an issue</a>
              </div>
            </div> */}
            </div>

            {/* <div className="navbar-end">
            <div className="navbar-item">
            <div className="buttons">
            <a className="button is-primary">
            <strong>Sign up</strong>
            </a>
            <a className="button is-light">Log in</a>
            </div>
            </div>
          </div> */}
          </div>
        </nav>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { pageTitle } = state.shared;
  return { pageTitle };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    null
  )
)(Nav);
