import { PAGE_TITLE, NETWORK } from "./constants/ActionTypes";

const initialState = {
  pageTitle: "Home",
  network: ""
};

const shared = (state = initialState, action) => {
  switch (action.type) {
    case PAGE_TITLE:
      return { ...state, pageTitle: action.pageTitle };
    case NETWORK:
      return { ...state, network: action.network };
    default:
      return state;
  }
};

export default shared;
