import * as types from "../../shared/constants/ActionTypes";
import axios from "axios";

// ***** Actions

// api fetch status
export const isFetchingStories = () => ({
  type: types.IS_FETCHING_STORIES
});

// sending all stories data to store
export const receivedStories = stories => ({
  type: types.STORIES_DATA,
  stories
});
// sending stories id
// export const storiesIdSelected = date => ({
//   type: types.STORIES_ID_SELECTED,
//   selectedIdStory: date
// });

//sending one selected stories
export const selectedStory = story => ({
  type: types.STORY_SELECTED,
  // selectedBreed: breed,
  selectedStory: story
});

// ******* Action Creators
//fetch the stories
export const getAllStories = () => {
  return (dispatch, getState) => {
    // declare a api fetch in progress
    dispatch(isFetchingStories());
    return axios({
      method: "get",
      url: "https://api.rss2json.com/v1/api.json",
      responseType: "json",
      params: {
        rss_url: "https://www.lovemeow.com/feeds/feed.rss",
        api_key: "1jocx7kvothnw9mvjz7ycn1tu4zi5yyc85bvpotp",
        count: 7
      }
    }).then(response => {
      dispatch(receivedStories(response.data.items));
    });
  };
};

// Selected one story
export const getOneStory = pubDate => {
  //   console.log(pubDate);
  return (dispatch, getState) => {
    // declare a api fetch in progress
    dispatch(isFetchingStories());
    // dispatch(resetSelectedStory());

    // filter the selected breed in store (see the request under)
    const storyFilterd = getState().stories.stories.filter(
      story => story.pubDate === pubDate
    );
    // console.log(storyFilterd);
    dispatch(selectedStory(storyFilterd));
    // get the selected breed img (seems that it return the breed too)
    // return request({
    //     method: "get",
    //     url: `/images/search?breed_id=${id}`
    // }).then(breed => {
    //     // return the selected breed data & image
    //     dispatch(selectedBreed(breed));
    // });
  };
};
