import React, { Component } from "react";
import styled from "styled-components";
import LazyImage from "react-lazy-progressive-image";

import "bulma/css/bulma.css";
import "bulma-helpers/css/bulma-helpers.min.css";

// lazy img style
const ImageWarpper = styled.div`
  /* width: 100%;
  height: 250px; */
`;
const Image = styled.img`
  /* content: ""; */
  margin: 0;
  padding: 0;
  width: 100%;
  /* min-height: 250px; */
  /* border-radius: 15px; */
  /* border: "1px solid black"; */
  display: block;
  transition: all 0.25s ease;
  opacity: ${props => (props.loading ? 0.2 : 1)};
`;

export default class Gallery extends Component {
  state = {
    openModal: false
  };

  // define if modal should be open or close
  showImg = () => {
    this.setState({ openModal: !this.state.openModal });
  };
  render() {
    const { url } = this.props; // from different parent
    const { openModal } = this.state;
    // define if modal should be open or close
    const isActive = openModal ? "is-active" : "";
    return (
      <ImageWarpper>
        <LazyImage
          placeholder={"/assets/loader.gif"}
          src={`${url}`}
          //setting of the Visibility sensor (https://github.com/joshwnj/react-visibility-sensor)
          visibilitySensorProps={{
            partialVisibility: true,
            minTopValue: 200
          }}
        >
          {(src, loading, isVisible) => (
            <div onClick={this.showImg}>
              <Image
                style={{
                  backgroundImage: `url(${src})`,
                  backgroundSize: "cover",
                  backgroundPosition: "50%",
                  objectFit: "cover"
                }}
                // src={src}
                // transparent big png for content, actual img is in background
                src={
                  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAACWCAQAAACWCLlpAAAA3klEQVR42u3QQREAAAwCoNm/9Cr49iACOWpRIEuWLFmyZCmQJUuWLFmyFMiSJUuWLFkKZMmSJUuWLAWyZMmSJUuWAlmyZMmSJUuBLFmyZMmSpUCWLFmyZMlSIEuWLFmyZCmQJUuWLFmyFMiSJUuWLFkKZMmSJUuWLAWyZMmSJUuWAlmyZMmSJUuBLFmyZMmSpUCWLFmyZMlSIEuWLFmyZCmQJUuWLFmyFMiSJUuWLFkKZMmSJUuWLAWyZMmSJUuWAlmyZMmSJUuBLFmyZMmSpUCWLFmyZMlSIEuWLFmbHrcjAJdeLWiCAAAAAElFTkSuQmCC"
                }
                loading={loading}
                alt="cats"
              />
            </div>
          )}
        </LazyImage>
        <div className={`modal ${isActive}`}>
          <div className="modal-background" />
          <div className="modal-content">
            <img src={`${url}`} alt="cat" />
          </div>
          <button
            onClick={this.showImg}
            className="modal-close is-large"
            aria-label="close"
          />
        </div>
      </ImageWarpper>
      /* <img
          src={`${url}`}
          alt="cats"
          className="has-background-light"
          style={{
            border: "1px solid black",
            maxWidth: "254px",
            borderRadius: "15px"
          }}
        /> */

      // <div className="box">
      //   <figure className="image is-1by1" key={id}>
      //     <img src={`${url}`} alt="cats" />
      //   </figure>
      // </div>
    );
  }
}
