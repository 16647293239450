import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bulma-helpers/css/bulma-helpers.min.css";
import catFacts from "./catFacts";

const RamdomFact = styled.div`
  margin: auto;
  padding: 1em;
  text-align: justify;
  color: whitesmoke;
`;

class Fact extends Component {
  state = {
    cats: undefined
  };

  componentDidMount = () => {
    //get one ramdom fact
    this.getData();
    // this.props
  };

  componentDidUpdate(prevProps, prevState) {
    //update fact when page change
    if (prevProps.location.pathname !== this.props.location.pathname) {
      //   console.log("if");
      this.getData();
    }
    // console.log("not if");
  }

  getData = () => {
    const fact = _.sample(catFacts);
    this.setState({
      fact: fact.fact,
      isFetching: "done"
    });
  };

  render() {
    const { fact } = this.state;
    const key = _.random(0, 100);
    return (
      <RamdomFact key={key}>
        <FontAwesomeIcon icon={["fas", "lightbulb"]} onClick={this.onRefresh} />{" "}
        {fact}
      </RamdomFact>
    );
  }
}

export default withRouter(Fact);
