import React, { Suspense, Component } from "react";
import request from "../../../shared/api/axios";
import PropTypes from "prop-types";

//Redux
import { connect } from "react-redux";
import { setPageTitle } from "../../../shared/actionCreators";
// styled Component
import styled from "styled-components";
import Select from "./../../../layout/styledComponents/select";
import MainBorder from "../../../layout/styledComponents/mainBorder";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bulma-helpers/css/bulma-helpers.min.css";

// lazy loading for ui render content faster befor image
// const Gallery = React.lazy(() => import("./../../shared/Gallery"));
import Gallery from "./../../shared/Gallery";

const ImgContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 1em;
`;

class RandomContainer extends Component {
  state = { cats: undefined, queryLimit: 4, refresh: false };

  componentDidMount = async () => {
    //set the title page
    this.props.setPageTitle("Random Cats");
    // await for network speed value from store
    await this.props.network;
    // get api data
    this.getData();
  };

  componentDidUpdate(prevProps, prevState) {
    // check if user click reload icon
    if (prevState.refresh !== this.state.refresh) {
      this.getData();
    }
  }

  getData = (limit = this.state.queryLimit) => {
    this.setState({ isFetching: true, refresh: false });
    const { network } = this.props;
    let size = "";
    network === "4g" ? (size = "full") : (size = "small");

    // fetch rand img
    let url = "/images/search?limit=" + limit + "&size=" + size;

    request({
      method: "get",
      url: url
    }).then(resp => {
      // console.log(resp);
      this.setState({
        cats: resp,
        isFetching: "done"
      });
    });
  };

  // setting the img number limit to get
  queryLimit = event => {
    this.setState({
      queryLimit: event.target.value
    });
    this.getData(event.target.value);
  };

  // refresh the page for new images
  onRefresh = () => {
    this.setState({
      refresh: true
    });
  };

  render() {
    const { cats, isFetching, queryLimit } = this.state;

    // making the image compo result
    const results =
      isFetching === "done" &&
      cats.map(cat => (
        <div key={cat.id} style={{ width: "50%" }}>
          <Gallery key={cat.id} id={cat.id} url={cat.url} />
        </div>
      ));
    return (
      <div>
        <div className="is-flex flex-row nowrap justify-center has-text-centered has-margin-bottom-10">
          <span className="has-margin-right-10 has-text-white">
            Images limit:
          </span>
          <form>
            <div className="field">
              <div>
                <Select onChange={this.queryLimit} value={queryLimit}>
                  <option id="4" value="4">
                    4
                  </option>
                  <option id="6" value="6">
                    6
                  </option>
                  <option id="8" value="8">
                    8
                  </option>
                </Select>
              </div>
            </div>
          </form>
          <span className="has-margin-left-10 has-text-white">
            <FontAwesomeIcon
              icon={["fas", "sync-alt"]}
              onClick={this.onRefresh}
            />
          </span>
        </div>
        <MainBorder>
          <Suspense
            fallback={
              <progress className="progress is-small is-primary" max="100">
                Loading...
              </progress>
            }
          >
            <ImgContainer>{results}</ImgContainer>
            {/* <div className="is-flex flex-column justify-center">{results}</div>
            <div style={{ width: "40%", height: "150px" }}>{results}</div> */}
          </Suspense>
        </MainBorder>
      </div>
    );
  }
}

RandomContainer.propTypes = {
  network: PropTypes.string,
  setPageTitle: PropTypes.func
};

function mapStateToProps(state) {
  const { network } = state.shared;
  return {
    network
  };
}

export default connect(
  mapStateToProps,
  { setPageTitle }
)(RandomContainer);
