import React, { Component } from "react";
import { Link } from "react-router-dom";

// import "./../../../theme.css";
// import "bulma/css/bulma.css";
import "bulma-helpers/css/bulma-helpers.min.css";

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "flex-end"
    // backgroundColor: "#5598FE",
    // height: "100%"
  },
  buttonLink: {
    marginTop: "1em",
    width: "250px"
  }
};

export default class Home extends Component {
  render() {
    return (
      <div style={styles.wrapper}>
        <div>
          <Link
            to="/random"
            style={styles.buttonLink}
            className="button is-link is-large is-rounded"
          >
            Random
          </Link>
        </div>
        <div>
          <Link
            to="/breed"
            style={styles.buttonLink}
            className="button is-link is-large is-rounded"
          >
            Breeds
          </Link>
        </div>
        <div>
          <Link
            to="/search"
            style={styles.buttonLink}
            className="button is-link is-large is-rounded"
          >
            Search
          </Link>
          <div>
            <Link
              to="/stories"
              style={styles.buttonLink}
              className="button is-link is-large is-rounded"
            >
              Stories
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
