import {
  IS_FETCHING_STORIES,
  //   GET_STORIES,
  STORIES_DATA,
  //   STORIES_ID_SELECTED,
  STORY_SELECTED,
  RESET_SELECTED_STORY
} from "../../shared/constants/ActionTypes";

const initialState = {
  isFetchingStories: false,
  stories: [],
  selectedIdStory: {},
  selectedStory: {}
};

const stories = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING_STORIES:
      return { ...state, isFetchingStories: !state.isFetchingStories };
    case STORIES_DATA: //all Categories
      return {
        ...state,
        isFetchingStories: !state.isFetchingStories,
        stories: action.stories
      };
    case RESET_SELECTED_STORY: // Categories menu
      return {
        ...state,
        selectedStories: {}
      };
    case STORY_SELECTED: // one breed selected
      // console.log(action.selectedBreedImg);
      return {
        ...state,
        isFetchingStories: !state.isFetchingStories,
        selectedStory: action.selectedStory
      };

    default:
      return state;
  }
};

export default stories;
