import React from "react";
import MainBorder from "../../../layout/styledComponents/mainBorder";
import { Link } from "react-router-dom";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";

const News = ({ pubDate, title, content }) => (
  <div>
    <div>
      <Link to="/stories">
        <span className="has-text-white has-margin-left-10">{`<- Back to stories`}</span>
      </Link>
    </div>
    <MainBorder style={{ margin: "0", paddingTop: "1em" }}>
      <article
        key={pubDate}
        className="article has-padding-left-10 has-padding-right-10"
      >
        <time dateTime={`${pubDate}`}>{pubDate}</time>
        <header>
          <h1>{title}</h1>
        </header>
        <section />
        <section dangerouslySetInnerHTML={{ __html: `${content}` }} />
        <footer />
      </article>
    </MainBorder>
    <ScrollUpButton />
  </div>
);

export default News;
