import { createSelector } from "reselect";

// const state = state => state;
const allBreedsSelectors = state => state.breed.breeds;
// const breedsScrollSelectors = state => state.breed.scrollBreeds;

export const scrollBreedsMenu = createSelector(
  allBreedsSelectors,
  breeds => {
    // console.log("scroll menu cached");

    const scrollData = breeds.map(breed => ({
      breedId: breed.id,
      name: breed.name
    }));

    // console.log(scrollData);
    return scrollData;
  }
);
