import React, { Suspense, Component } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import PropTypes from "prop-types";
//Redux
import { connect } from "react-redux";
import { getAllBreeds, getOneBreeds } from "../actionCreators";
import { setPageTitle } from "../../../shared/actionCreators";
import { scrollBreedsMenu } from "../selectors";

import "bulma/css/bulma.css";
import "bulma-helpers/css/bulma-helpers.min.css";
import "./breed.css";

// lazy loading for ui render content faster befor image
const Breed = React.lazy(() => import("./Breed"));
// const BreedList = React.lazy(() => import("./BreedList"));

// const style = {
//   wrapper: {
//     // backgroundColor: "#8e44ad",
//     margin: "0",
//     display: "flex",
//     justifyContent: "center"
//     // alignItems: "center"
//   },
//   card: {
//     display: "flex",
//     alignItems: "center",
//     backgroundColor: "#fff",
//     minWidth: "40px",
//     maxHeight: "90px",
//     overflowX: "auto"
//   }
// };

//**** Making the breeds menu */
const list = [
  { breedId: "abys", breedName: "Abyssinian" },
  { breedId: "aege", breedName: "Aegean" },
  { breedId: "abob", breedName: "American Bobtail" },
  { breedId: "acur", breedName: "American Curl" },
  { breedId: "asho", breedName: "American Shorthair" },
  { breedId: "awir", breedName: "American Wirehair" },
  { breedId: "amau", breedName: "Arabian Mau" },
  { breedId: "amis", breedName: "Australian Mist" },
  { breedId: "bali", breedName: "Balinese" },
  { breedId: "bamb", breedName: "Bambino" },
  { breedId: "beng", breedName: "Bengal" },
  { breedId: "birm", breedName: "Birman" },
  { breedId: "bomb", breedName: "Bombay" },
  { breedId: "bslo", breedName: "British Longhair" },
  { breedId: "bsho", breedName: "British Shorthair" },
  { breedId: "bure", breedName: "Burmese" },
  { breedId: "buri", breedName: "Burmilla" },
  { breedId: "cspa", breedName: "California Spangled" },
  { breedId: "ctif", breedName: "Chantilly-Tiffany" },
  { breedId: "char", breedName: "Chartreux" },
  { breedId: "chau", breedName: "Chausie" },
  { breedId: "chee", breedName: "Cheetoh" },
  { breedId: "csho", breedName: "Colorpoint Shorthair" },
  { breedId: "crex", breedName: "Cornish Rex" },
  { breedId: "cymr", breedName: "Cymric" },
  { breedId: "cypr", breedName: "Cyprus" },
  { breedId: "drex", breedName: "Devon Rex" },
  { breedId: "dons", breedName: "Donskoy" },
  { breedId: "lihu", breedName: "Dragon Li" },
  { breedId: "emau", breedName: "Egyptian Mau" },
  { breedId: "ebur", breedName: "European Burmese" },
  { breedId: "esho", breedName: "Exotic Shorthair" },
  { breedId: "hbro", breedName: "Havana Brown" },
  { breedId: "hima", breedName: "Himalayan" },
  { breedId: "jbob", breedName: "Japanese Bobtail" },
  { breedId: "java", breedName: "Javanese" },
  { breedId: "khao", breedName: "Khao Manee" },
  { breedId: "kora", breedName: "Korat" },
  { breedId: "kuri", breedName: "Kurilian" },
  { breedId: "lape", breedName: "LaPerm" },
  { breedId: "mcoo", breedName: "Maine Coon" },
  { breedId: "mala", breedName: "Malayan" },
  { breedId: "manx", breedName: "Manx" },
  { breedId: "munc", breedName: "Munchkin" },
  { breedId: "nebe", breedName: "Nebelung" },
  { breedId: "norw", breedName: "Norwegian Forest Cat" },
  { breedId: "ocic", breedName: "Ocicat" },
  { breedId: "orie", breedName: "Oriental" },
  { breedId: "pers", breedName: "Persian" },
  { breedId: "pixi", breedName: "Pixie-bob" },
  { breedId: "raga", breedName: "Ragamuffin" },
  { breedId: "ragd", breedName: "Ragdoll" },
  { breedId: "rblu", breedName: "Russian Blue" },
  { breedId: "sava", breedName: "Savannah" },
  { breedId: "sfol", breedName: "Scottish Fold" },
  { breedId: "srex", breedName: "Selkirk Rex" },
  { breedId: "siam", breedName: "Siamese" },
  { breedId: "sibe", breedName: "Siberian" },
  { breedId: "sing", breedName: "Singapura" },
  { breedId: "snow", breedName: "Snowshoe" },
  { breedId: "soma", breedName: "Somali" },
  { breedId: "sphy", breedName: "Sphynx" },
  { breedId: "tonk", breedName: "Tonkinese" },
  { breedId: "toyg", breedName: "Toyger" },
  { breedId: "tang", breedName: "Turkish Angora" },
  { breedId: "tvan", breedName: "Turkish Van" },
  { breedId: "ycho", breedName: "York Chocolate" }
];
// One item component
// selected prop will be passed
const MenuItem = ({ text, selected }) => {
  return <div className="menu-item">{text}</div>;
};
// All items component
// Important! add unique key
export const Menu = list =>
  list.map(el => {
    const { breedName, breedId } = el;

    return <MenuItem text={breedName} key={breedId} id={breedId} />;
  });

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
const ArrowRight = Arrow({ text: ">", className: "arrow-next" });

/*** end Breeds Menu */

class BreedContainer extends Component {
  /*
  breeds: all breeds data fetch at once. use for making the breed menu and breed detail
  breedSelected: the id of breed selected
  breedImg: the breed selected img
  showBreed: tell if the component detail have to be displayed
  breedResult: the specific breed details for detail page
  breedScroll: the list name of the breed 
  menuSeleceted: the breed name selected in the menu
  */
  state = {
    selected: 0
  };

  async componentDidMount() {
    //set the title page
    this.props.setPageTitle("Cats Breeds");
    // Fetch all breeds & store it via Redux Actions
    this.props.getAllBreeds();
    // show first breeds for exemple
    this.props.getOneBreeds("abys");
  }

  onSelect = key => {
    this.setState({ selected: key });
    this.props.getOneBreeds(key);
  };
  // breedsScrollMenu = () => {
  //   // this.props.isFetchingBreeds === false &&
  //   let scroll = this.props.scrollBreeds.map(breed => (
  //     <BreedsScroll
  //       key={breed.breedId}
  //       id={breed.breedId}
  //       name={breed.breedName}
  //       img={breed.img}
  //       breedIdSelected={breedIdSelected}
  //       onClick={this.props.getOneBreeds}
  //     />
  //   ));
  //   return scroll;
  // };

  // async get one breed image
  // async getImg(id) {
  //   let data = {};
  //   try {
  //     const response = await request({
  //       method: "get",
  //       url: `/images/search?breed_id=${id}&size=thumb`
  //     }).then(res => {
  //       data = res[0].url;
  //       this.setState({ imgBreed: { ...this.state.imgBreed, [id]: data } });
  //       return response;
  //     });
  //     return data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // create the scroll breed menu. TODO: Could add some images
  // scrollSelect() {
  //   let scrollData = [];
  //   this.props.isFetchingBreeds === false &&
  //     this.props.breeds.forEach(async element => {
  //       // await this.getImg(element.id);
  //       scrollData.push({
  //         breedId: element.id,
  //         breedName: element.name
  //         // img: this.state.imgBreed[element.id]
  //       });
  //     });

  //   // send to state
  //   this.setState({
  //     breedScroll: scrollData
  //   });
  // }

  render() {
    const { selected } = this.state;
    const menu = Menu(list, selected);

    return (
      <div className="">
        <nav className="">
          <div className="App">
            <ScrollMenu
              data={menu}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              selected={selected}
              onSelect={this.onSelect}
            />
          </div>
        </nav>
        <main>
          <Suspense
            fallback={
              <progress className="progress is-small is-primary" max="100">
                Loading...
              </progress>
            }
          >
            {this.props.selectedBreed.length > 0 && (
              <Breed {...this.props.selectedBreed} />
            )}
          </Suspense>
        </main>
      </div>
    );
  }
}

BreedContainer.propTypes = {
  getAllBreeds: PropTypes.func,
  getOneBreeds: PropTypes.func,
  // selectedBreed: PropTypes.shape({
  //   breeds: PropTypes.array,
  //   id: PropTypes.string,
  //   url: PropTypes.string
  // }),
  selectedBreed: PropTypes.any,
  setPageTitle: PropTypes.func
};

/*
isFetchingBreeds: if an api is in action,
breeds: all the breeds data,
selectedbreed: the breed the user select to see,
scrollBreeds: the breed data to populate the menu
*/
function mapStateToProps(state) {
  const { isFetchingBreeds, breeds, selectedBreed } = state.breed;
  return {
    isFetchingBreeds,
    breeds,
    selectedBreed,
    scrollBreeds: scrollBreedsMenu(state)
  };
}

export default connect(
  mapStateToProps,
  { getAllBreeds, getOneBreeds, setPageTitle }
)(BreedContainer);

//https://thecatapi.com/v1/images?api_key=ABC123
