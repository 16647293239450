import styled from "styled-components";

const Select = styled.select`
  border: 2px solid rgb(177, 96, 254);
  border-radius: 2900486px;
  font-size: 0.75em;
  padding-right: 2.5em;
  padding-left: 1em;
  margin-right: 1em;

  option {
    color: white;
    background: rgb(177, 96, 254);
    font-weight: small;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export default Select;
