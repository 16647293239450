import React, { Component } from "react";
import Fact from "./../features/shared/Fact";
import { Link } from "react-router-dom";

export default class Footer extends Component {
  componentDidMount = () => {};

  render() {
    var divStyle = {
      // color: "white",
      //   backgroundImage: "url(" + imgUrl + ")",
      backgroundColor: "#5598FE",
      maxWidth: "100%"
      // height: "50px"
    };

    // var ads = {
    //   maxWidth: "100%",
    //   height: "50px"
    // };

    return (
      <div style={divStyle}>
        <Fact />
        <footer className="footer is-hidden-mobile is-hidden-tablet-only">
          <nav className="tabs  is-centered">
            <ul>
              <li>
                <Link to="/privacy">Privacy</Link>
              </li>
              <li>
                <Link to="/terms">Terms and conditions</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </nav>
          {/* <div className="media-content">
            <div className="content has-text-centered" style={ads}>
              <iframe
                src={`https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=26&l=ur1&category=animalerie&banner=0MJR87P9ZN9GMVZQSV82&f=ifr&linkID=4e05ece5980f83e0f64d1f35fd4318a5&t=mydailycats-21&tracking_id=mydailycats-21`}
                width={468}
                height={60}
                scrolling={"no"}
                border={0}
                marginWidth={0}
                style={{ border: "none" }}
                frameBorder={0}
                title="amazon banner"
              />
            </div>
          </div> */}
          <div className="is-hidden-mobile has-text-centered is-hidden-tablet-only">
            <a
              href="https://play.google.com/store/apps/details?id=com.haidraco.felikitty"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div style={{ width: "30%", margin: "auto" }}>
                <img src="./assets/playstore.png" alt="logo" />
              </div>
              <div>Download our application from the Google Play Store</div>
            </a>
          </div>
        </footer>
      </div>
    );
  }
}
