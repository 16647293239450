import React, { Component } from "react";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
//Redux
import { connect } from "react-redux";
import { setPageTitle } from "../../../shared/actionCreators";

import Story from "./Story";

class StoryContainer extends Component {
  async componentDidMount() {
    //set the title page
    this.props.setPageTitle("Story");
    //check if story isn't null
    if ((await this.props.selectedStory.length) < 1) {
      this.props.history.push("/stories");
    }
  }

  render() {
    const { selectedStory } = this.props;
    console.log(selectedStory);
    // if (selectedStory[0].pubDate === undefined || selectedStory.length < 1) {
    //   this.props.history.push("/stories");
    // }
    return (
      <div>
        {selectedStory && (
          <Story
            pubDate={selectedStory[0].pubDate}
            title={selectedStory[0].title}
            content={selectedStory[0].content}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { selectedStory } = state.stories;
  return { selectedStory };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { setPageTitle }
  )
)(StoryContainer);
