// Display always PublicNav and dispatch components
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

// import "bulma/css/bulma.css";
// import "./../theme.css";
import "bulma-helpers/css/bulma-helpers.min.css";

import Home from "../features/Home";
import Nav from "./Nav";
import Random from "../features/Random";
import Breed from "../features/Breed";
import { Stories, Story } from "../features/Stories";
import Search from "../features/Search";
import Privacy from "./../features/Privacy";
import Terms from "./../features/Terms";
import Contact from "./../features/Contact";
import Footer from "./Footer";

export default class DefaultLayout extends Component {
  render() {
    return (
      <div
        // className="columns is-gapless is-paddingless"
        style={{
          backgroundColor: "#ececec",
          minHeight: "100vh",
          margin: "0 auto",
          padding: "0",
          maxWidth: "700px"
        }}
      >
        <div
        // className="column is-desktop is-half is-offset-one-quarter"
        // style={{ padding: "0.75rem 0 0 0.75rem" }}
        >
          <div className="">
            <Nav />
          </div>
          <div
            className=""
            style={{
              backgroundColor: "#5598FE",
              minHeight: "58vh"
            }}
          >
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/random" component={Random} />
              <Route path="/breed" component={Breed} />
              <Route path="/stories" component={Stories} />
              <Route path="/story" component={Story} />
              <Route path="/search" component={Search} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/terms" component={Terms} />
              <Route path="/contact" component={Contact} />
            </Switch>
          </div>
          <div className="">
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
