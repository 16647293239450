import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import ReactGA from "react-ga";

//Redux
import { connect } from "react-redux";
import { getNetwork } from "./shared/actionCreators";

//fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import {
  faStar as fasStar,
  faSyncAlt as fasSyncAlt,
  faLightbulb as fasLightbulb
} from "@fortawesome/free-solid-svg-icons";

import DefaultLayout from "./layout/Default";

// G Analytics config stuff
ReactGA.initialize("UA-136450704-1");
// ip anonimizer
ReactGA.set({ anonymizeIp: true });
ReactGA.pageview(window.location.pathname + window.location.search);
// add icons
library.add(fasStar, farStar, fasSyncAlt, fasLightbulb);

class App extends Component {
  componentDidMount = () => {
    // Network speed Information API
    let connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;
    // get conn type at 1st load
    this.detectNetwork();
    // listen if connection speed type change
    connection.addEventListener("change", this.detectNetwork);
  };

  // send to the store the network speed
  detectNetwork = () => {
    const { effectiveType } = navigator.connection;
    const { getNetwork } = this.props;
    getNetwork(effectiveType);
    // console.log(effectiveType);
  };

  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={DefaultLayout} />
          <Route exact path="/random" component={DefaultLayout} />
          <Route exact path="/breed" component={DefaultLayout} />
          <Route path="/stories" component={DefaultLayout} />
          <Route path="/story" component={DefaultLayout} />
          <Route exact path="/search" component={DefaultLayout} />
          <Route exact path="/privacy" component={DefaultLayout} />
          <Route exact path="/terms" component={DefaultLayout} />
          <Route exact path="/contact" component={DefaultLayout} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(
  connect(
    null,
    { getNetwork }
  )(App)
);
