import * as types from "./constants/ActionTypes";

// set the title
export const setPageTitle = title => ({
  type: types.PAGE_TITLE,
  pageTitle: title
});

// pass the network speed to store
export const setNetwork = network => ({
  type: types.NETWORK,
  network
});

// ******* Action Creators

// receive the network speed from app.js
export const getNetwork = network => {
  return (dispatch, getState) => {
    dispatch(setNetwork(network));
  };
};
