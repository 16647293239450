export const IS_FETCHING = "IS_FETCHING";

//Breeds
export const IS_FETCHING_BREEDS = "IS_FETCHING_BREEDS";
export const RECEIVED_BREEDS = "RECEIVED_BREEDS";
export const SCROLL_BREEDS = "SCROLL_BREEDS"; // the breed menu
export const SELECTED_BREED = "SELECTED_BREED";
export const RESET_SELECTED_BREED = "RESET_SELECTED_BREED";
export const GET_BREEDS = "GET_BREEDS";

//Search Global
export const IS_FETCHING_CATEGORIES = "IS_FETCHING_CATEGORIES";
export const CATEGORIES_DATA = "CATEGORIES_DATA";
export const DROPDOWN_CATEGORIES = "DROPDOWN_CATEGORIES"; // the cat dropdown menu
export const SELECTED_CATEGORIES = "SELECTED_CATEGORIES";
export const RESET_SELECTED_CATEGORIES = "RESET_SELECTED_CATEGORIES";
export const GET_CATEGORIES = "GET_CATEGORIES";

// Stories
export const IS_FETCHING_STORIES = "IS_FETCHING_STORIES";
export const GET_STORIES = "GET_STORIES";
export const STORIES_DATA = "STORIES_DATA";
export const STORIES_ID_SELECTED = "STORIES_ID_SELECTED";
export const STORY_SELECTED = "STORY_SELECTED";
export const RESET_SELECTED_STORY = "RESET_SELECTED_STORY";

//Shared
export const PAGE_TITLE = "PAGE_TITLE";
export const NETWORK = "NETWORK";
