import * as types from "../../shared/constants/ActionTypes";
import request from "../../shared/api/axios";

// ***** Actions

// api fetch status
export const isFetchingBreeds = () => ({
  type: types.IS_FETCHING_BREEDS
});

// sending all breeds data to store
export const receivedBreeds = breeds => ({
  type: types.RECEIVED_BREEDS,
  breeds: breeds
});

//sending one selected breed
export const selectedBreed = breedImg => ({
  type: types.SELECTED_BREED,
  // selectedBreed: breed,
  selectedBreedImg: breedImg
});

// making the scroll menu
export const scrollBreeds = breed => ({
  type: types.SCROLL_BREEDS,
  scrollBreeds: breed
});

// reset selected breed
export const resetSelectedBreed = () => ({
  type: types.RESET_SELECTED_BREED
});

// ******* Action Creators

//fetch the breeds
export const getAllBreeds = () => {
  return (dispatch, getState) => {
    // declare a api fetch in progress
    dispatch(isFetchingBreeds());
    return request({
      method: "get",
      url: "/breeds"
    }).then(response => {
      dispatch(receivedBreeds(response));

      // making the dropdown/scroll breeds menu /!\ for Breed page made with selectors

      let scrollData = [];
      response.forEach(element => {
        scrollData.push({
          breedId: element.id,
          breedName: element.name
        });
      });
      dispatch(scrollBreeds(scrollData));
    });
  };
};

// Selected one breed
export const getOneBreeds = id => {
  return (dispatch, getState) => {
    // declare a api fetch in progress
    dispatch(isFetchingBreeds());
    dispatch(resetSelectedBreed());

    // filter the selected breed in store (see the request under)
    // const breedFilterd = getState().breed.breeds.filter(
    //   breed => breed.id === id
    // );
    // console.log(breedFilterd);
    // get the selected breed img (seems that it return the breed too)
    let size = "";
    let network = getState().shared.network;
    network === "4g" ? (size = "medium") : (size = "small");
    return request({
      method: "get",
      url: `/images/search?breed_id=${id}&limit=4&size=${size}`
    }).then(breed => {
      // return the selected breed data & image
      dispatch(selectedBreed(breed));
    });
  };
};
