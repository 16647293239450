import {
  IS_FETCHING_BREEDS,
  RECEIVED_BREEDS,
  SELECTED_BREED,
  RESET_SELECTED_BREED,
  SCROLL_BREEDS
} from "../../shared/constants/ActionTypes";

const initialState = {
  isFetchingBreeds: false,
  breeds: [],
  selectedBreed: {},
  scrollBreeds: []
};

const breeds = (state = initialState, action) => {
  switch (action.type) {
    case IS_FETCHING_BREEDS:
      return { ...state, isFetchingBreeds: !state.isFetchingBreeds };
    case RECEIVED_BREEDS: //all breeds
      return {
        ...state,
        isFetchingBreeds: !state.isFetchingBreeds,
        breeds: action.breeds
      };
    case SCROLL_BREEDS: // breeds menu
      return {
        ...state,
        scrollBreeds: action.scrollBreeds
      };
    case RESET_SELECTED_BREED: // breeds menu
      return {
        ...state,
        selectedBreed: {}
      };
    case SELECTED_BREED: // one breed selected
      // console.log(action.selectedBreedImg);
      return {
        ...state,
        isFetchingBreeds: !state.isFetchingBreeds,
        selectedBreed: action.selectedBreedImg
        // {
        // data: action.selectedBreed,
        // img: action.selectedBreedImg
        // }
      };

    default:
      return state;
  }
};

export default breeds;
