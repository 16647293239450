import React, { Component } from "react";
//Redux
import { connect } from "react-redux";
import { setPageTitle } from "../../../shared/actionCreators";

import Home from "./Home";

import "./../../../theme.css";
import "bulma-helpers/css/bulma-helpers.min.css";

class HomeCompo extends Component {
  componentDidMount = () => {
    //set the title page
    this.props.setPageTitle("Home");
  };

  render() {
    return (
      <div className="is-marginless is-paddingless" style={{ height: "84vh" }}>
        <Home />
      </div>
    );
  }
}

export default connect(
  null,
  { setPageTitle }
)(HomeCompo);
